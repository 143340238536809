<div
    class="main-container"
    [dir]="dir"
    [ngClass]="{
        minisidebar: minisidebar,
        boxed: boxed,
        danger: danger,
        blue: blue,
        green: green,
        horizontal: horizontal
    }"
>
    <mat-toolbar color="primary" class="topbar relative">
        <div class="w-100 fix-width-for-horizontal">
            <!-- <button mat-icon-button (click)="snav.toggle()" class="sidebar-toggle" value="sidebarclosed">
                <mat-icon>menu</mat-icon>
            </button> -->

            <h2 style="margin: 5px 0 0 0px; font-weight: 600" class="header-responstive">
                {{ 'common.title' | translate }}
            </h2>

            <span fxFlex></span>

            <div>
                <app-vertical-header [isShowHome]="isShowOptionHome()"></app-vertical-header>
            </div>
        </div>
    </mat-toolbar>

    <mat-sidenav-container
        class="example-sidenav-container"
        [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
        [ngClass]="{ minisidebar: minisidebar }"
    >
        <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''">
            <div class="page-content">
                <router-outlet>
                    <app-spinner></app-spinner>
                </router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
