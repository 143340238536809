import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';

@Injectable({ providedIn: 'root' })
export class TaiSanDanhMucDataService {
    private _data: Map<string, any[]> = new Map();
    private _dataSub: Subject<Map<string, any[]>> = new Subject();

    public get data(): Map<string, any[]> {
        return this._data;
    }
    public set data(value: Map<string, any[]>) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<Map<string, any[]>> {
        return this._dataSub;
    }

    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async fetch() {
        let result: any = await this.httpClient
            .get<any>(this.hostnameService.HidsAPI_Readv1 + '/categories', {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
        if (result.status == 'error') this.data = new Map();
        let data: Map<string, any[]> = new Map();
        if (result.categories) {
            result.categories.forEach((category: any) => {
                if (!category['value']) return;

                let type = category['type'];
                if (!data.has(type)) data.set(type, []);
                data.get(type)?.push({
                    ma: category['value'],
                    ten: category['value'],
                });
            });
            data.forEach((value: any[], key: string) => {
                data.set(
                    key,
                    value.sort((a: any, b: any) => {
                        if (a.ma > b.ma) return 1;
                        if (a.ma < b.ma) return -1;
                        return 0;
                    })
                );
            });
        }
        this.data = data;
        return this.data;
    }

    async fetchIfNotExists() {
        if (typeof this._data == 'object' && !isNaN(this._data.size) && this._data.size > 0) return this.data;
        return await this.fetch();
    }

    getByType(type: string) {
        return this.data.get(type) || [];
    }
}
