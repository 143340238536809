import { MCODES } from './roles.menu.utils';

export const MENU_ITEMS_DASHBOARD = {
    state: 'dashboards',
    name: 'menuitems.dashboard',
    type: 'link',
    icon: 'av_timer',
};

export const MENU_ITEMS_TAIKHOAN = {
    state: 'account',
    name: 'menuitems.tai_khoan',
    type: 'sub',
    icon: 'manage_accounts',
    children: [{ state: 'account-info', name: 'menuitems.account_info', type: 'link' }],
};

export const MENU_ITEMS_LUUTRU = {
    state: 'storage',
    name: 'menuitems.luu_tru',
    type: 'sub',
    icon: 'archive',
    children: [{ state: 'files-downloaded', name: 'menuitems.file_downloaded', type: 'link' }],
};

export const MENU_ITEMS_TIENICH = {
    state: 'utitlities',
    name: 'menuitems.tien_ich',
    type: 'link',
    icon: 'extension',
};

export const MENU_ITEMS_CONFIG = {
    state: ['config', 'setup'],
    name: 'menuitems.config',
    type: 'link',
    icon: 'settings',
};


export const MENU_ITEMS_ASSET = {
    state: ['asset', 'list'],
    name: 'menuitems.asset',
    type: 'link',
    icon: 'work',
};

export const MENU_ITEMS_CATEGORY = {
    state: 'category',
    name: 'menuitems.category',
    type: 'sub',
    icon: 'dataset',
    children: [
        { state: 'don-vi', name: 'categories.danh_muc.don_vi', type: 'link' },
        { state: 'kieu-tai-san', name: 'categories.danh_muc.kieu_tai_san', type: 'link' },
        { state: 'trang-thai', name: 'categories.danh_muc.trang_thai', type: 'link' },
        { state: 'nha-cung-cap', name: 'categories.danh_muc.nha_cung_cap', type: 'link' },
        { state: 'dia-diem-mac-dinh', name: 'categories.danh_muc.dia_diem_mac_dinh', type: 'link' },
    ],
};

export const MENU_ITEMS_STAFF = {
    state: ['staff', 'manage-staff-account'],
    name: 'menuitems.staff',
    type: 'link',
    icon: 'account_circle',
};

export const MENU_ITEMS: Map<string, any> = new Map<string, any>([
    [MCODES.dashboard, MENU_ITEMS_DASHBOARD],
    [MCODES.config, MENU_ITEMS_CONFIG],
    [MCODES.asset, MENU_ITEMS_ASSET],
    [MCODES.category, MENU_ITEMS_CATEGORY],
    [MCODES.staff, MENU_ITEMS_STAFF],
    [MCODES.luutru, MENU_ITEMS_LUUTRU],
    [MCODES.tienich, MENU_ITEMS_TIENICH],
    [MCODES.taikhoan, MENU_ITEMS_TAIKHOAN],
]);

export const MENU_ITEMS_ORDER: string[] = [
    MCODES.dashboard,
    MCODES.asset,
    MCODES.category,
    MCODES.staff,
    MCODES.luutru,
    MCODES.tienich,
    MCODES.config,
    MCODES.taikhoan,
];
