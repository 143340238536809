import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HostnameService {
    get HidsAPI() {
        return 'https://api.asset.ten-mien.net';
    }

    get HidsAPI_Readv1() {
        return "https://api.asset.ten-mien.net/api/v1/read";
        // return 'http://localhost:5000';
    }

    get HidsAPI_Producerv1() {
        return "https://api.asset.ten-mien.net/api/v1/producer";
        // return 'http://localhost:5001';
    }

    get Current_Webbapp() {
        return "https://asset.ten-mien.net";
        // return 'http://localhost:4200';
    }
}
