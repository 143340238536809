import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './authentication/keycloak/auth.guard';
import { AssetDetailLayoutComponent } from './layouts/asset-detail/asset-detail.component';

export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        data: { someRoles: ['admin', 'asset.chuyenvien', 'asset.lanhdao'] },
        children: [
            {
                path: '',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboards',
                loadChildren: () => import('./dashboards/dashboards.module').then((m) => m.DashboardsModule),
            },

            // Account
            {
                path: 'account',
                loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
            },

            // Storage
            {
                path: 'storage',
                loadChildren: () => import('./storage/files.module').then((m) => m.StorageModule),
            },

            // Asset
            {
                path: 'asset',
                loadChildren: () => import('./tai-san/tai-san.module').then((m) => m.AssetModule),
            },
            // Categories
            {
                path: 'category',
                loadChildren: () => import('./danh-muc/danh-muc.module').then((m) => m.CategoriesModule),
            },
            // Staff
            {
                path: 'staff',
                loadChildren: () => import('./staff-account/staff-account.module').then((m) => m.StaffAccountModule),
            },
            // Config
            {
                path: 'config',
                loadChildren: () => import('./cau-hinh/cau-hinh.module').then((m) => m.ConfigModule),
            },
        ],
    },
    {
        path: '',
        component: AssetDetailLayoutComponent,
        canActivate: [AuthGuard],
        data: { someRoles: ['admin', 'public.assetdetail'] },
        children: [
            // Asset
            {
                path: 'asset-detail',
                loadChildren: () => import('./tai-san-public/tai-san.module').then((m) => m.AssetDetailModule),
            },
        ],
    },
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren: () =>
                    import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
            },
        ],
    },
    // {
    //     path: '**',
    //     redirectTo: 'authentication/404',
    // },
];
