// CONSTABCE
export const MCODES = {
    dashboard: 'dashboard', // Bảng điều khiển
    luutru: 'luutru', // Lưu trữ
    tienich: 'tienich', // Tiện ích
    taikhoan: 'taikhoan', // Tài khoản

    config: 'config',
    asset: 'asset',
    category: 'category',
    staff: 'staff',
};

// ROLES

// prettier-ignore
export const MENU_ITEMS_ROLES: Map<string, string[]> = new Map([
    ['admin', [
        MCODES.dashboard, MCODES.luutru, MCODES.taikhoan, 
        MCODES.config, 
        MCODES.asset, 
        MCODES.category, 
        MCODES.staff,
    ]],

    // CAN_BO
    ['asset.chuyenvien', [
        MCODES.dashboard, MCODES.luutru, MCODES.taikhoan, 
        MCODES.asset, 
        MCODES.category, 
    ]],
    ['asset.lanhdao', [
        MCODES.dashboard, MCODES.luutru, MCODES.taikhoan, 
        MCODES.config, 
        MCODES.asset, 
        MCODES.category, 
        MCODES.staff,
    ]],
    
]);

// FUNCTIONS
export function menu_items_check_for_role(roles: string[]) {
    let menu_items: Set<string> = new Set();
    roles.forEach((role: string) => {
        let menu_for_role = MENU_ITEMS_ROLES.get(role);
        if (!Array.isArray(menu_for_role)) return;
        menu_for_role.forEach((item: string) => menu_items.add(item));
    });
    return [...menu_items];
}
