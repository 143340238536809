import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import Swal from 'sweetalert2';
import { HostnameService } from './hostname.service';

export interface AuthencationResponse {
    code: string;
    token: string;
}

export interface AccountMetadata {
    ma_don_vi: string;
    apikey: string;
    ma_cb: string;
    avatar: string;
}

@Injectable({ providedIn: 'root' })
export class AccountService {
    constructor(
        private httpClient: HttpClient,
        private hostnameService: HostnameService,
        private keyCloakService: KeycloakService
    ) {}

    getMetadata() {
        let keyCloakInstance: any = this.keyCloakService.getKeycloakInstance();
        let attributes = keyCloakInstance.profile.attributes;

        let metadata: AccountMetadata = {
            ma_don_vi: '',
            apikey: '',
            ma_cb: '',
            avatar: '',
        };
        if (attributes.ma_don_vi) metadata.ma_don_vi = attributes.ma_don_vi[0];
        if (attributes.apikey) metadata.apikey = attributes.apikey[0];
        if (attributes.ma_cb) metadata.ma_cb = attributes.ma_cb[0];
        if (attributes.avatar) metadata.avatar = attributes.avatar[0];
        return metadata;
    }

    getUserRoles(): string[] {
        let keyCloakInstance: any = this.keyCloakService.getKeycloakInstance();
        let parseToken = keyCloakInstance?.tokenParsed;
        let userRoles: string[] = [];
        if (parseToken?.resource_access['webapp-asset']) {
            userRoles = parseToken?.resource_access['webapp-asset'].roles;
        }
        return userRoles;
    }

    getSubject() {
        return this.keyCloakService.getKeycloakInstance().subject;
    }

    async getProfile(forceReload: boolean = false) {
        await this.keyCloakService.loadUserProfile(forceReload);
        return this.keyCloakService.getKeycloakInstance().profile;
    }

    getRoles(): string[] {
        return this.keyCloakService.getUserRoles();
    }

    getAPIKey() {
        return this.getMetadata().apikey;
    }

    getCode() {
        return this.getMetadata().ma_don_vi;
    }

    getToken(): string | undefined {
        return this.keyCloakService.getKeycloakInstance().token;
    }

    isLanhDao() {
        let roles: string[] = this.getRoles();
        return roles.includes('asset.lanhdao');
    }
}
