import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { AccountService } from 'src/app/providers/account.service';

/** @title Responsive sidenav */
@Component({
    selector: 'app-asset-detail-layout',
    templateUrl: 'asset-detail.component.html',
    styleUrls: ['./asset-detail.component.scss'],
})
export class AssetDetailLayoutComponent implements OnDestroy {
    public mobileQuery: MediaQueryList;

    public dir = 'ltr';
    public dark = false;
    public minisidebar = false;
    public boxed = false;
    public horizontal = false;

    public green = false;
    public blue = false;
    public danger = false;
    public showHide = false;
    public url = '';
    public sidebarOpened = false;
    public status = false;

    public showSearch = false;
    public config: PerfectScrollbarConfigInterface = {};
    // tslint:disable-next-line - Disables all
    private _mobileQueryListener: () => void;

    constructor(
        public router: Router,
        public changeDetectorRef: ChangeDetectorRef,
        public media: MediaMatcher,
        private accountService: AccountService
    ) {
        this.mobileQuery = media.matchMedia('(min-width: 1023px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);
        // this is for dark theme
        // const body = document.getElementsByTagName('body')[0];
        // body.classList.toggle('dark');
        this.dark = false;
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    ngOnInit() {
        //const body = document.getElementsByTagName('body')[0];
        // body.classList.add('dark');
    }

    isShowOptionHome() {
        let user_roles = this.accountService.getUserRoles();
        return user_roles.includes('asset.chuyenvien') || user_roles.includes('asset.lanhdao');
    }

    clickEvent(): void {
        this.status = !this.status;
    }

    darkClick() {
        // const body = document.getElementsByTagName('body')[0];
        // this.dark = this.dark;
        const body = document.getElementsByTagName('body')[0];
        body.classList.toggle('dark');
        // if (this.dark)
        // else
        // 	body.classList.remove('dark');
        // this.dark = this.dark;
        // body.classList.toggle('dark');
        // this.dark = this.dark;
    }
}
