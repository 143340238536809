import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TaiSanRefService {
    private _data: any[] = [];
    private _dataSub: Subject<any[]> = new Subject();

    public get data(): any[] {
        return this._data;
    }
    public set data(value: any[]) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<any[]> {
        return this._dataSub;
    }

    constructor() {}
}
